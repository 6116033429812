<template>
  <header />
</template>

<script>
export default {
  name: 'Header',
};
</script>

<style lang="scss" scoped>
  header{

  }

</style>
