<template>
  <component
    :is="data.tag"
    :class="['label', `typo--${data.size}`, `color--${data.color}`, data]"
    v-html="data.value"
  />
</template>

<script>
export default {
  name: 'Label',
  props: {
    data: {
      type: Object,
      default: () => ({
        value: '',
        tag: 'h3',
        size: 'm',
      }),
    },
  },
};
</script>

<style>

.color--red{
  background: #EF2718!important;
}

.color--green{
  background: #38AA35!important;
}

.color--yellow{
  background: #FFDD00!important;
}
.color--orange{
  background: #EF7D00!important;
}
.color--blue{
  background: #5FC4E1!important;
}

</style>
