<template>
  <figure
    :class="['picture', vh ? 'full-height' : false]"
    :data-lazy="lazy ? 'false' : null"
  >
    <picture
      v-if="imageType !== 'svg'"
      class="img"
      :style="{ paddingBottom: !vh ? `${ratio}%` : false }"
    >
      <source
        v-for="(image, key) in sizes"
        :key="key"
        :data-srcset="image.url"
        :media="image.size"
        :data-media="key"
      >
      <img
        ref="image"
        class="image"
        :data-src="defaultImage.sizes.l"
        :alt="defaultImage.alt"
      >
      <div class="placeholder" />
    </picture>
    <div
      v-else-if="imageType === 'inline-svg'"
      class="svg"
      v-html="data.svg"
    />
    <Flex
      v-else-if="imageType === 'svg'"
      align="center"
      class="svg-wrapper"
    >
      <img
        class="svg"
        :src="defaultImage.url"
        :alt="defaultImage.alt"
      >
    </Flex>
    <figcaption
      v-if="caption !== ''"
      v-html="caption"
    />
  </figure>
</template>

<script>
export default {
  name: 'Figure',
  props: {
    data: {
      type: Object,
      required: true,
    },
    vh: {
      type: Boolean,
      default: false,
    },
    lazy: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    defaultImage() {
      if (this.data.default) {
        return this.data.default;
      }
      return this.data;
    },
    imageType() {
      if (this.data.type === 'svg') {
        return 'inline-svg';
      }
      if (this.defaultImage?.subtype === 'svg+xml') {
        return 'svg';
      }
      return 'image';
    },
    ratio() {
      if (!this.data && !this.defaultImage) return 0;
      return (this.defaultImage.height / this.defaultImage.width) * 100;
    },
    sizes() {
      if (!this.data && !this.defaultImage) return [];

      let mobileSizes = null;
      const { sizes } = this.defaultImage;
      if (this.data.mobile) {
        mobileSizes = this.data.mobile.sizes;
      }

      const ratio = window.devicePixelRatio > 1 ? 2 : 1;

      return {
        xxs: {
          url: mobileSizes?.xxs || sizes.xxs,
          size: `(max-width: ${sizes['xxs-width'] / ratio}px)`,
        },
        xs: {
          url: mobileSizes?.xs || sizes.xs,
          size: `(max-width: ${sizes['xs-width'] / ratio}px)`,
        },
        s: {
          url: mobileSizes?.s || sizes.s,
          size: `(max-width: ${sizes['s-width'] / ratio}px)`,
        },
        m: {
          url: sizes.m,
          size: `(max-width: ${(sizes['m-width'] * 2) / ratio}px)`,
        },
        l: {
          url: sizes.l,
          size: `(max-width: ${(sizes['l-width'] * 2) / ratio}px)`,
        },
        xl: {
          url: sizes.xl,
          size: `(max-width: ${(sizes['xl-width'] * 2) / ratio}px)`,
        },
        xxl: {
          url: sizes.xxl,
          size: `(min-width: ${(sizes['xxl-width'] * 2) / ratio}px)`,
        },
      };
    },
    caption() {
      if (this.defaultImage.caption) {
        return this.defaultImage.caption;
      }
      return '';
    },
  },
  mounted() {
    if (!this.lazy) {
      this.load();
    }
  },
  methods: {
    async load() {
      const img = this.$refs.image;
      if (img && img.decode) {
        await img.decode();
        this.loaded = true;
      } else {
        this.loaded = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.picture {
  position: relative;
  overflow: hidden;

  picture {
    position: relative;
    overflow: hidden;
    display: block;
    height: 0;
  }

  &.full-height {
    picture {
      height: 100vh;
    }
  }

  .image {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .placeholder {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    opacity: 1;
    pointer-events: none;
    transition: opacity 0.5s $ease-custom;

    background-color: whitesmoke;
    will-change: opacity;
  }

  figcaption {
    text-align: left;
  }

  &[data-lazy="true"] {
    .placeholder {
      opacity: 0;
    }
  }
  .svg-wrapper {
    height: 100%;
    position: relative;
  }
}
</style>
