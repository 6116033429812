<template>
  <main>
    <!-- <Spacer :x="{default: 'm', m: 'l'}"> -->
    <Section
      v-for="(section, parentIndex) in post.acf.sections"
      :key="parentIndex"
      :layout="layout(section)"
      :class="`section--${parentIndex}`"
    >
      <component
        :is="components.get(block.acf_fc_layout)"
        v-for="(block, index) in section.blocks"
        :key="index"
        :class="`block-${block.acf_fc_layout}`"
        :data="block"
      />
    </Section>
  </main>
</template>

<script>
import components from '@/assets/js/components';
import data from '@/mixins/data';
import lazyload from '@/mixins/lazyload';

import Section from '@/components/section';
import Content from '@/components/blocks/content';
import Media from '@/components/blocks/media';
import Repeater from '@/components/blocks/repeater';
import Map from '@/components/blocks/map';
import List from '@/components/blocks/list';
import Archive from '@/components/blocks/archive';
import Placeholder from '@/components/blocks/placeholder';

export default {
  name: 'Page',
  components: {
    Section,
    Content,
    Media,
    Repeater,
    Map,
    List,
    Archive,
    Placeholder,
  },
  mixins: [data, lazyload],
  data() {
    return {
      components,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });

    const allFulls = document.querySelectorAll('.section__full');
    allFulls.forEach((item) => {
      const fullImage = item.querySelector('.block-media');
      const fullDesc = item.querySelector('.wysiwyg');
      if (fullDesc) {
        const fullDescHeight = fullDesc.offsetHeight;

        if (window.innerWidth < 999) {
          fullImage.style.paddingBottom = `${fullDescHeight + 125}px`;
        } else {
          fullImage.style.paddingBottom = 0;
        }
      }
    });
  },
  methods: {
    onResize() {
      const allFulls = document.querySelectorAll('.section__full');
      allFulls.forEach((item) => {
        const fullImage = item.querySelector('.block-media');
        const fullDesc = item.querySelector('.wysiwyg');
        if (fullDesc) {
          const fullDescHeight = fullDesc.offsetHeight;

          if (window.innerWidth < 999) {
            fullImage.style.paddingBottom = `${fullDescHeight + 125}px`;
          } else {
            fullImage.style.paddingBottom = 0;
          }
        }
      });
    },

    layout(section) {
      const {
        section_background, section_classes, section_layout, section_layout_custom, section_text,
      } = section;
      return {
        section_background, section_classes, section_layout, section_layout_custom, section_text,
      };
    },
  },
};
</script>
