<template>
  <footer
    class="page-footer"
    :class="
      $route.name === '404'
        ? 'footer__404'
        : null || $route.params.slug === 'contatti'
          ? 'footer__contatti'
          : null
    "
    @mousemove="onMove($event)"
    @ontouchmove="onMove($event)"
  >
    <div
      class="footer__eyes"
      @click="randomRotate"
    >
      <div class="footer__more">
        Vuoi saperne di più?
      </div>
      <div class="eyes">
        <div class="eye">
          <div class="pupil" />
        </div>

        <div class="eye" />
      </div>
      <div
        v-if="options && options.social"
        class="footer__buttons"
      >
        <div
          v-for="(item, index) in options.social"
          :key="index"
          class="footer__button"
        >
          <a
            :href="item.url"
            v-html="item.label"
          />
        </div>

        <!-- <div class="footer__button">
          Scopri i prodotti
        </div>
        <div class="footer__button">
          Trova il negozio più vicino
        </div>
        <div class="footer__button">
          Contattaci
        </div> -->
      </div>
      <div class="footer__contatti--extra">
        <Spacer :x="{ default: 'm', m: 'l' }">
          <!-- CIAOOO
        {{ options.text }}
        {{ options.mail }} -->
          <!-- <Richtext
          class=""
          :data="{ value: options.text }"
        /> -->
          <div
            v-if="options && options.description"
            class="text"
            v-html="options.description"
          />
          <div
            v-if="options && options.credits"
            class="page-footer__credits"
          >
            <Flex
              v-for="(item, index) in options.credits"
              :key="index"
              align="h-between"
            >
              <div
                class="button"
                v-html="item.content"
              />
            </Flex>
          </div>
          <Spacer />
        </Spacer>
      </div>
    </div>
    <div class="footer__description">
      <Spacer :x="{ default: 'm', m: 'l', xl: 'xl', xxl: 'xxl' }">
        <div
          v-if="options && options.list"
          class="page-footer__list"
        >
          <Flex
            v-for="(item, index) in options.list"
            :key="index"
            align="h-between"
          >
            <div v-html="item.label" />
            <div v-html="item.content" />
          </Flex>
        </div>
      </Spacer>
    </div>
  </footer>
</template>

<script>
// import Richtext from '@/components/typo/richtext';

export default {
  name: 'Footer',
  components: {
    // Richtext,
  },
  computed: {
    options() {
      const { footer } = this.$store.state.wp.options;
      return footer;
    },
    contatti() {
      return this.$store.state.wp.contatti;
    },
  },
  mounted() {
    // document.addEventListener('mousemove', this.onMove);
    this.randomRotate2();

    this.randomRotate();
  },
  methods: {
    onMove(event) {
      const eyes = document.querySelectorAll('.eye');
      eyes.forEach((eye) => {
        const x = eye.getBoundingClientRect().left + eye.clientWidth / 2;
        const y = eye.getBoundingClientRect().top + eye.clientHeight / 2;

        // eye.style.top = `${event.pageX - x}px`;

        // eye.style.left = event.pageY - y - window.scrollY;
        // console.log(event.pageX - x);
        const radian = Math.atan2(
          event.pageX - x,
          event.pageY - y - window.scrollY,
        );
        const rot = radian * (180 / Math.PI) * -1 + 270;
        eye.style.transform = `rotate(${rot}deg)`;
      });
    },
    randomRotate() {
      const buttons = document.querySelectorAll('.footer__button');
      buttons.forEach((item) => {
        const randomNum = Math.floor(Math.random() * (15 - -15 + 1)) + -15;
        const randomNumDeg = `${randomNum}deg`;
        item.style.transform = `rotate(${randomNumDeg})`;
      });
    },
    randomRotate2() {
      const buttons = document.querySelectorAll('.button');
      buttons.forEach((item) => {
        const randomNum = Math.floor(Math.random() * (4 - -4 + 1)) + -4;
        const randomNumDeg = `${randomNum}deg`;
        item.style.transform = `rotate(${randomNumDeg})`;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
footer {
  margin-top: 8 * $unit;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  @include mq(s) {
    margin-top: 15 * $unit;
  }
  @include mq(m) {
    margin-top: 22.5 * $unit;
  }
  .footer__contatti--extra {
    display: none;
  }

}
.footer__404 {
  margin-top: 0;
  display: none;
}
.footer__contatti {

  a {
    color: black !important;
    &:hover {
      color: black !important;
    }
  }
  margin-top: 0;
  .footer__eyes {
    position: relative;
  }
  .footer__more {
    display: none;
  }
  .footer__buttons {
    display: none !important;
  }
  .eyes {
    // top: -20vh;
    padding-bottom: 2*$unit;
    @include mq(xxxs) {
      display: flex;
      // top: -15vh;
    }
    @include mq(s) {
      top: -8vh;
    }
    @include mq(m) {
      top: -2vh;
          padding-bottom: 0*$unit;

    }
    @include mq(xl) {
      top: -4vh;
    }
    @include mq(xxxl) {
      top: -5vh;
    }
  }

  .footer__contatti--extra {
    display: block;
    bottom: 1.5 * $unit !important;
    left: 0;
    font-size: 16px;
    line-height: 1.2;
    width: 100%;
    @include mq(xxxs) {
      bottom: 2 * $unit !important;
    }
    @include mq(s) {
          font-size: 20px;
    position: absolute;

      bottom: 3 * $unit !important;
    }
    @include mq(m) {
      font-size: 24px;
    }

    @include mq(xxxl) {
      font-size: 30px;
      bottom: 6 * $unit !important;
    }

    .spacer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include mq(m) {
        align-items: flex-start;
      }
    }
    .text {
      font-weight: 400;
      max-width: 100%;
      text-align: center;
      padding-bottom: 1.5 * $unit;
      @include mq(xxxs) {
        padding-bottom: 2 * $unit;
      }
      @include mq(s) {
        padding-bottom: 3 * $unit;
      }
      @include mq(s) {
        max-width: 80%;
      }
      @include mq(m) {
        text-align: left;
        max-width: 70%;
      }
        @include mq(l) {
        text-align: left;
        max-width: 48%;
      }
        @include mq(xxl) {
        text-align: left;
        max-width: 40%;
      }
          @include mq(xxxl) {
        text-align: left;
        max-width: 45%;
      }
    }
    .page-footer__credits {
      display: flex;
      align-items: center;
      flex-direction: column;

 img{
    max-height: 30px!important;
    height: 30px;
  }
      @include mq(s) {
        flex-direction: row;
      }
      .flex {
        justify-content: flex-start;
      }
      .button {
        background-color: $yellow;
        border-radius: 30px;
        padding: 1 * $unit 3 * $unit 1.3 * $unit 3 * $unit;
        margin-top: 1 * $unit;
        transition: all 0.4s cubic-bezier(0.62, -1.97, 0.4, 3.27);
        width: fit-content;
        font-size: 14px;
        @include mq(xxxs) {
          font-size: 16px;
        }
        @include mq(xxs) {
          font-size: 20px;
          margin-right: 1.5 * $unit;
        }
        @include mq(s) {
                  margin-bottom: 1 * $unit;
          font-size: 22px;
          margin-right: 1.5 * $unit;
        }
        @include mq(m) {
          font-size: 28px;
          margin-right: 2 * $unit;
        }
      }
    }
  }
}

.footer__eyes {
  flex-grow: 2;
  background: $blue;
  width: 100%;
  padding: 14 * $unit 1.5 * $unit 2 * $unit 1.5 * $unit;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @include mq(m) {
    padding: 8 * $unit 4 * $unit;
  }
}

.eyes {
  position: relative;
  top: -10px;
  display: flex;
}

.eyes .eye {
  transition: transform 0.1s ease-in-out;
  position: relative;
  width: 120px;
  height: 120px;
  display: block;
  background: #fff;
  margin: 0 15px;
  border-radius: 50%;
  @include mq(s) {
    width: 180px;
    height: 180px;
  }
  @include mq(m) {
    width: 220px;
    height: 220px;
  }
  @include mq(xxl) {
    width: 265px;
    height: 265px;
  }
}

.pupil{

}
.eyes .eye:before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 35px;
  transform: translate(-50%, -50%);
  width: 70px;
  height: 70px;
  background: #1d1d1b;
  border-radius: 50%;
  @include mq(s) {
    left: 55px;
    width: 110px;
    height: 110px;
  }
  @include mq(m) {
    left: 63px;
    width: 126px;
    height: 126px;
  }
  @include mq(xxl) {
    left: 76px;
    width: 152px;
    height: 152px;
  }
}

.footer__description {
  background: $yellow;
  height: auto;
  text-align: center;
  @extend %typo--xs;
  .spacer {
    height: 100%;
  }
  @include mq(s) {
  }
  @include mq(l) {
    height: 68px;
    text-align: left;
  }
}

.page-footer__list {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 1.5 * $unit 0 1.3 * $unit 0;
  @include mq(l) {
    padding: 0;
    justify-content: space-between;
  }
  .flex {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 0.4 * $unit;
    @include mq(l) {
      width: auto;
    }
  }
}

.footer__more {
  padding-bottom: 8 * $unit;
  @extend %typo--l;
  text-align: center;
  @include mq(xxl) {
    padding-bottom: 12 * $unit;
  }
}

.footer__buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  @extend %typo--s;
  padding-top: 5 * $unit;
  padding-bottom: 2 * $unit;
  @include mq(m) {
    flex-direction: row;
  }
  @include mq(xxl) {
    padding-top: 10 * $unit;
  }
  a:hover {
    color: black !important;
  }
}
.footer__button {
  background-color: $yellow;
  border-radius: 30px;
  padding: 1.4 * $unit 3 * $unit 1.6 * $unit 3 * $unit;
  margin-top: 1 * $unit;
  margin-bottom: 1 * $unit;
  transition: all 0.4s cubic-bezier(0.62, -1.97, 0.4, 3.27);
  width: fit-content;
  @include mq(m) {
    margin-left: 1 * $unit;
    margin-right: 1 * $unit;
  }
}

.button:hover {
  transform: rotate(0) !important;
}

.footer__button:hover {
  transform: rotate(0) !important;
}
</style>
