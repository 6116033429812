<template>
  <Spacer
    :x="{ default: 'm', m: 'l' }"
    class="mappa__wrapper"
  >
    <div class="sidebar">
      <div
        id="listings"
        class="listings"
      >
        <div
          v-for="item in data.locations"
          :key="item.message"
        >
          <div
            class="store"
            :class="[
              { 'store--active': currentStore === item.map.place_id },
              { 'store--active': currentPhone === item.telephone },
            ]"
            @click="flyMap(item.map.lng, item.map.lat, item.map.place_id)"
          >
            <div class="store__wrapper">
              <div class="store__top">
                <p>{{ item.city }}</p>
                <p>{{ item.name }}</p>
                <p class="store__top--small">
                  {{ item.map.name }}
                </p>
              </div>
              <div class="store__bottom">
                <div class="store__bottom--left">
                  <p>
                    {{ item.referente }}
                  </p>
                  <p>
                    <a :href="'mailto:'+item.mail">{{ item.mail }}</a>
                  </p>
                </div>
                <div class="store__bottom--right">
                  <p>
                    <a :href="'tel:'+item.telephone">
                      {{ item.telephone }}
                    </a>

                    <a
                      v-if="item.telephone_2"
                      :href="'tel:'+item.telephone_2"
                    >
                      <br>{{ item.telephone_2 }}
                    </a>
                  </p>
                  <!-- <div
                    v-for="day in item.days_and_hours"
                    :key="day.message"
                  >
                    <p>{{ day.days }}: {{ day.hours }}</p>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="map"
      class="map"
    />

    <!-- {{ data.locations[0].map }} -->
  </Spacer>
</template>

<script>
import mapboxgl from 'mapbox-gl';

export default {
  name: 'Map',
  components: {},
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      map: null,
      currentStore: null,
      currentPhone: null,
    };
  },
  computed: {},

  mounted() {
    // for (let index = 0; index < this.data.locations.length; index + 1) {
    //   const element = index;
    //   console.log(element);
    // }
    const $vm = this;

    mapboxgl.accessToken = 'pk.eyJ1IjoibXgyIiwiYSI6ImNrMTZmZHBscDAwZzYzaG5vMmh6aHF2OHAifQ.EO7nHD6WdufEXGNG0eKcNg';
    this.map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [12.888783161874994, 42.09473199471419],
      zoom: 5,
      scrollZoom: false,
    });
    // console.log(map);

    const stores = {
      type: 'FeatureCollection',
      features: [],
    };

    this.data.locations.forEach((location) => {
      // console.log(location);
      stores.features.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [location.map.lng, location.map.lat],
        },
        properties: {
          phoneFormatted: location.telephone,
          phone: location.telephone,
          address: location.map.address,
          city: location.map.city,
          country: location.map.country,
          crossStreet: '',
          postalCode: location.map.post_code,
          state: location.map.country,
        },
      });
    });

    function addMarkers(map) {
      stores.features.forEach((marker) => {
        const el = document.createElement('div');
        el.id = `marker-${marker.properties.id}`;
        el.className = 'marker';

        new mapboxgl.Marker(el, { offset: [0, -13] })
          .setLngLat(marker.geometry.coordinates)
          .addTo(map);

        el.addEventListener('click', () => {
          map.flyTo({
            center: marker.geometry.coordinates,
            zoom: 13,
          });

          const activeItem = document.getElementsByClassName('store--active');
          if (activeItem[0]) {
            activeItem[0].classList.remove('store--active');
          }

          $vm.currentPhone = marker.properties.phone;
        });
      });
    }

    this.map.on('load', () => {
      // this.map.addLayer({
      //   id: 'locations',
      //   type: 'circle',
      //   /* Add a GeoJSON source containing place coordinates and information. */
      //   source: {
      //     type: 'geojson',
      //     data: stores,
      //   },
      // });
      this.map.addSource('places', {
        type: 'geojson',
        data: stores,
      });
      addMarkers(this.map);
    });
  },
  methods: {
    flyMap(lng, lat, id) {
      const activeItem = document.getElementsByClassName('store--active');
      if (activeItem[0]) {
        activeItem[0].classList.remove('store--active');
      }
      this.currentStore = id;

      this.map.flyTo({
        center: [lng, lat],
        zoom: 13,
      });

      // document.querySelectorAll('.store').forEach((item) => {
      //   item.style.background = 'white';
      // });
      // document.querySelector(`.${id}`).style.background = 'red';
    },
  },
};
</script>

<style >
a:hover{
  color: #5AC4E8;
}
</style>
