import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

import Page from '@/views/Page.vue';
import Single from '@/views/Single.vue';
// HINT: Adding a custom Post Type - 2
import SingleWork from '@/views/SingleWork.vue';
import NotFound from '@/views/404.vue';

const singles = new Map();

singles.set('post', Single);
singles.set('work', SingleWork || Single);

Vue.use(VueRouter);

// eslint-disable-next-line no-undef
const { show_on_front, page_on_front } = __VUE_WORDPRESS__.routing;
// eslint-disable-next-line no-undef
const { postTypes } = __VUE_WORDPRESS__.state;
// eslint-disable-next-line no-undef
const { name: siteTitle, description } = __VUE_WORDPRESS__.state.site;

const routes = [
  {
    path: '/404',
    name: '404',
    component: NotFound,
  },
  {
    path: '/',
    name: 'Index',
    component: Page,
    meta: {
      slug: page_on_front,
      type: show_on_front ? 'pages' : 'posts',
    },
  },
  {
    path: '/:slug',
    name: 'Page',
    component: Page,
  },
];

Object.keys(postTypes).forEach((key) => {
  if (key !== 'page') {
    routes.push({
      path: `/${postTypes[key].rewrite.slug}/:slug`,
      name: singles.get(key).name,
      component: singles.get(key),
      meta: {
        type: postTypes[key].rest_base,
      },
    });
  }
});

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : '/',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.name === '404') {
    document.title = `404 — ${siteTitle}`;
    next();
    return;
  }

  const slug = to.meta.slug ? to.meta.slug : to.params.slug;

  const request = {
    type: to.meta.type || 'pages',
    slug,
  };

  store.dispatch('getSingleBySlug', request).then((page) => {
    if (page && slug) {
      if (to.path === '/') {
        document.title = `${siteTitle} — ${description}`;
      } else {
        document.title = `${page.title.rendered} — ${siteTitle}`;
      }
      next();
    } else {
      console.log('404');
      router.replace('/404');
    }
  });
});

export default router;
