const components = new Map();

components.set('content', 'Content');
components.set('media', 'Media');
components.set('repeater', 'Repeater');
components.set('list', 'List');
components.set('archive', 'Archive');
components.set('map', 'Map');
components.set('placeholder', 'Placeholder');

export default components;
