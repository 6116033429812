<template>
  <div class="notfound">
    <div>404<br>Page not found<br><br>:(</div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style lang="scss" scoped>

.notfound{
  background: $yellow;
  height: 100vh;
  // padding: 20*$unit 4*$unit;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  @extend %typo--xl;
}

</style>
