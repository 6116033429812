<template>
  <nav :class="{ nav__up: navUp, nav__background : navBackground, 'nav__contatti' : $route.params.slug === 'contatti'}">
    <Spacer :x="{default: 'm', m: 'l'}">
      <Flex class="nav__flex">
        <div
          v-for="item in navigation"
          :key="item.id"
          :class="item.css"
        >
          <router-link
            :to="item.url"
            :class="item.css + '--link'"
          >
            {{ item.content }}
          </router-link>
        </div>
        <div
          class="nav__burger"
          :class="{'nav__burger--close' : menuOpen }"
          @click="openMenu"
        />
      </Flex>
      <!-- <transition name="slide-fade"> -->
      <div
        v-if="menuOpen"
        class="nav__mobile"
      >
        <div
          v-for="item in navigation"
          :key="item.id"
          :class="item.css"
        >
          <router-link
            :to="item.url"
            :class="item.css + '--link'"
          >
            {{ item.content }}
          </router-link>
        </div>
      </div>
      <!-- </transition> -->
    </Spacer>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Menu',
  data() {
    return {
      prevScrollpos: window.pageYOffset,
      navUp: false,
      menuOpen: false,
      navBackground: false,
    };
  },
  computed: {
    ...mapGetters(['menu']),
    navigation() {
      const nav = this.menu('navigation');
      return nav ? nav.items : null;
    },
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        this.menuOpen = false;
      }
    },
  },
  mounted() {
    // this.consolla();
    document.addEventListener('scroll', this.moveHeader);
    this.$aion.add(this.checkHeader, 'checkHeader', true);
  },
  beforeDestroy() {
    this.$aion.remove('checkHeader');
  },
  methods: {
    checkHeader() {
      // console.log('ciao');
    },
    moveHeader() {
      const currentScrollPos = window.pageYOffset;
      const hero = document.querySelector('.section__homepage');
      const contatti = document.querySelector('.footer__contatti');
      if (this.prevScrollpos > currentScrollPos) {
        this.navUp = false;
      } else if (currentScrollPos > 100) {
        this.navUp = true;
      }
      this.prevScrollpos = currentScrollPos;
      if (hero) {
        if (currentScrollPos > 100) {
          this.navBackground = true;
        } else {
          this.navBackground = false;
        }
      } else if (contatti) {
        this.navBackground = false;
      } else {
        this.navBackground = true;
      }
      setTimeout(() => {
        if (contatti) {
          this.navBackground = false;
        }
      }, 200);
    },
    openMenu() {
      this.menuOpen = !this.menuOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
nav {
  @extend %typo--s;
  position: fixed;
  z-index: 10;
  width: calc(100% - 48px);
  padding: 1.5 * $unit 0;
  transition: all 0.4s cubic-bezier(0.78, 0.01, 0.29, 1);
  background: white;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
  top: 1.5 * $unit;
  left: 2.4* $unit;
  border-radius: 3 * $unit;
  @include mq(m) {
    background: white;
    width: 100%;
    padding: 1 * $unit 0;
    border-radius: 0;
    top: 0;
    left: 0;
    box-shadow: none;
  }

  @include mq(l){
    padding: 1.2*$unit 0;
  }
   @include mq(xxl) {
       padding: 2 * $unit 0;
  }
}
.nav__up {
  @include mq(m) {
    transform: translateY(-100%);
  }
}

a{
  color: black;
  &:hover{
      color:$yellow;
      mix-blend-mode: multiply;
  }
}

.nav__background{
  @include mq(m){
    background: white !important;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
  }

}
.nav__burger {
  height: 4 * $unit;
  width: 4 * $unit;
  padding-right: $unit;
  background-image: url("~@/assets/img/burger.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: $unit;
  @include mq(m) {
    display: none;
  }
  &--close{
  background-image: url("~@/assets/img/close.svg");

  }
}
.nav__contatti{

    @include mq(m) {
     background: none!important;
  box-shadow: none!important;
  }
}
.nav__flex {
  align-items: center;
}
.nav__1 {
  margin-right: 9 * $unit;
  display: none;
  @include mq(m) {
    display: inline-block;
  }
}
.nav__2 {
  margin-right: auto;
  display: none;
  @include mq(m) {
    display: inline-block;
  }
}
.nav__3 {
  margin-left: auto;
  display: none;
  @include mq(m) {
    display: inline-block;
  }
}
.nav__4 {
  margin-left: 9 * $unit;
  display: none;
  @include mq(m) {
    display: inline-block;
  }
}
.nav__logo {
  height: 4 * $unit;
  width: 10 * $unit;
  margin: auto;
  @include mq(xs) {
    height: 4 * $unit;
  width: 12 * $unit;
  }

  @include mq(m) {
    height: 4 * $unit;
    width: 11 * $unit;
  }
   @include mq(xl) {
    height: 5 * $unit;
    width: 13 * $unit;
  }
    @include mq(xxl) {
          height: 6 * $unit;
    width: 15 * $unit;
  }
  &--link {
    display: inline-block;
    width: 100%;
    height: 100%;
    color: transparent;
    background-image: url("~@/assets/img/mumble.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    &:hover{
    color: transparent!important;

    }
  }
}
.nav__mobile {
  display: flex;
  flex-direction: column;
  padding: 4*$unit 0 3*$unit 0;
  @include mq(m){
    display: none;
  }
  .nav__1,
  .nav__2,
  .nav__3,
  .nav__4 {
    display: inline-block;
    margin: auto;
    padding-bottom: 1.5*$unit;
    @extend %typo--l;
  }
  .nav__logo {
    display: none;
  }
  .nav__logo:hover{
    display: none;

  }
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
</style>
