<template>
  <Spacer :x="{default: 'm', m: 'l', xl : 'xl', xxl : 'xxl'}">
    <component
      :is="components[data.type]"
      :data="data[data.type]"
    />
  </Spacer>
</template>

<script>
import { Figure, Video, Gallery } from '@/components/media';

export default {
  name: 'MediaSection',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      components: {
        image: Figure,
        video: Video,
        gallery: Gallery,
      },
    };
  },
};
</script>

<style>

</style>
